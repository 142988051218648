@import url('../assets/css/fractal.css');
@import '../../node_modules/@dnb/mijndnb-ui/assets/mijndnb-ui/styles/typography/fonts.css';

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: #f0f4f9;
	background-color: var(--color-dnb-catskill-blauw);
	background-image: url('/assets/dnb.nl/media/bg-wave.svg');
	background-repeat: no-repeat;
	background-position: right -40rem top -30rem;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2.4rem;
	line-height: var(--line-height-m);
	font-family: Verdana, Geneva, sans-serif;
	font-family: var(--main-font-stack);
	color: #4d596c;
	color: var(--main-font-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

main {
	flex: 1;
}