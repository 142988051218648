@import '../../node_modules/@dnb/supervision-core/src/styles/mijndnb/mijndnb-dlt-custom.scss';

/* global style.css overrides */
.page-header__title {
	background-color: var(--color-dnb-leigrijs);
}

a {
	color: var(--color-dnb-blauw);
}

/* this is a reminder selector for the annoying mat radio label with no text, remove this when they fix that 8px wide nothingness */
.mat-radio-no-label > label.mat-radio-label > span.mat-radio-label-content {
	display: none;
	overflow: hidden;
	width: 0px;
	height: 0px;
	padding: 0px;
}

.btn--quaternary {
	display: flex;
	width: 100%;
	padding: 1.6rem;
	padding: var(--spacing-m);
	border: 0.1rem solid #e7eaf3;
	border: 0.1rem solid var(--color-dnb-catskill-white);
	background-color: #68778d;
	background-color: var(--color-dnb-catskill-white);
	font-family: 'DNB-Fedra', sans-serif;
	font-family: var(--heading-font-stack);
	font-size: 1.8rem;
	font-size: var(--font-size-l);
	font-weight: 600;
	font-weight: var(--font-weight-medium);
	color: #2d2166;
	color: var(--color-dnb-blauw-1);
}

.btn--quaternary:hover,
.btn--quaternary:focus {
	background-color: #2d2166;
	background-color: var(--color-dnb-blauw-1);
	text-decoration: none;
	color: #fff;
	color: var(--color-white);
}

.btn--quaternary:hover .btn__icon,
.btn--quaternary:focus .btn__icon {
	color: #fff;
	color: var(--color-white);
}

.btn--quaternary .btn__text {
	padding-right: 1.6rem;
	padding-right: var(--spacing-m);
}

.btn--quaternary .btn__icon {
	width: 1.6rem;
	height: 1.6rem;
	margin-left: auto;
	color: #2d2166;
	color: var(--color-dnb-blauw-1);
}

.rte .btn--linklist {
	margin-top: 0.8rem;
	display: block;
	color: var(--color-dnb-blauw-2);
}

.main-grid-layout__sidebar-top {
	display: none;
}

.text-block__container {
	display: flex;
	flex-direction: row;
	margin-bottom: 1.6rem;
}

.text-block__container--image {
	display: none;
}

.text-block__container--image img {
	display: flex;
	max-width: none;
	padding: 1.6rem;
}

.rte a[href^="https://"]::after, .rte a[href^="http://"]::after
{
	display: inline-block;
	height: 1.6rem;
	width: 1.6rem;
	margin-left: 0.4rem;
	margin-bottom: 0.3rem;
	background-repeat: no-repeat;
	background-position: center;
	vertical-align: middle;
	content: '';
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%2344287f' d='M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm16 400c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h352c8.822 0 16 7.178 16 16v352zM99.515 374.828c-4.686-4.686-4.686-12.284 0-16.971l195.15-195.15-.707-.707-89.958.342c-6.627 0-12-5.373-12-12v-9.999c0-6.628 5.372-12 12-12L340 128c6.627 0 12 5.372 12 12l-.343 136c0 6.627-5.373 12-12 12h-9.999c-6.627 0-12-5.373-12-12l.342-89.958-.707-.707-195.15 195.15c-4.686 4.686-12.284 4.686-16.971 0l-5.657-5.657z'%3E%3C/path%3E%3C/svg%3E");
}

.rte a[href^="https://www.dnb.nl"]::after, .rte a[href^="http://www.dnb.nl"]::after
{
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%2344287f' d='M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm16 400c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h352c8.822 0 16 7.178 16 16v352zM99.515 374.828c-4.686-4.686-4.686-12.284 0-16.971l195.15-195.15-.707-.707-89.958.342c-6.627 0-12-5.373-12-12v-9.999c0-6.628 5.372-12 12-12L340 128c6.627 0 12 5.372 12 12l-.343 136c0 6.627-5.373 12-12 12h-9.999c-6.627 0-12-5.373-12-12l.342-89.958-.707-.707-195.15 195.15c-4.686 4.686-12.284 4.686-16.971 0l-5.657-5.657z'%3E%3C/path%3E%3C/svg%3E");
}

.external-link {
	display: inline-block;
}

@media (min-width: 62em) {
	.main-navigation__logo {
		padding: var(--spacing-s) var(--spacing-l);
	}

	.main-grid-layout__sidebar-top {
		display: block;
	}

	.text-block__container--image {
		display: flex;
		width: 150px;
		height: 80px;
		background-color: var(--color-dnb-catskill-white);
		margin-right: 2.4rem;
	}
}

.mdc-text-field--outlined {
	--mdc-outlined-text-field-container-shape: 0px !important;

	&:hover {
		--mdc-outlined-text-field-outline-width: 2px !important; 
	}
}

.mat-mdc-form-field {
	width: 100%;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
	padding: 10px 8px 0px 0px !important;
}

.matSelectAlignment {
	margin-top: -0 !important;
	margin-left: 5px !important;
}

.mat-mdc-dialog-container, .mat-mdc-dialog-container .mdc-dialog__container, .mat-mdc-dialog-container .mdc-dialog__surface {
	padding: 24px 16px 0px 16px !important;
}

.mdc-dialog .mdc-dialog__content {
    padding: 1px 8px !important;
}